import { PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { LocalStorageKeys } from '../constants/enums';

let timer: any;
export function debounceLogic(callbackFn: (params?: string) => void, time: number = 1000) {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout((params: any) => {
    callbackFn(params);
  }, time);
}

export const getPopupRequest = (email: string) => {
  return {
    scopes: ['User.Read', 'profile', 'openid', 'email'],
    loginHint: email,
  } as PopupRequest;
};

export const getRedirectRequestPayload = (email: string) => {
  return {
    scopes: ['User.Read', 'profile', 'openid', 'email'],
    loginHint: email,
  } as RedirectRequest;
};

export const getSsoSilentRequestPayload = (email: string) => {
  return { loginHint: email } as SsoSilentRequest;
};

export const getAccessTokenInLocalStorage = () => {
  return localStorage.getItem(LocalStorageKeys.AccessToken);
};

export const setAccessTokenInLocalStorage = (token: string) => {
  localStorage.setItem(LocalStorageKeys.AccessToken, token);
};

export const getLoggedInUserEmail = () => {
  return localStorage.getItem(LocalStorageKeys.LoggedInUserEmail);
};
export const setLoggedInUserEmail = (email: string) => {
  return localStorage.setItem(LocalStorageKeys.LoggedInUserEmail, email);
};

export const getIsBrowserIncognito = () => {
  return localStorage.getItem(LocalStorageKeys.IsBrowserIncognito);
};

export const setIsBrowserIncognito = (isBrowserIncognito: boolean) => {
  return localStorage.setItem(LocalStorageKeys.IsBrowserIncognito, isBrowserIncognito.toString());
};

export const parseAzureADToken = (token: string) => {
  if (!token) {
    return null;
  }
  const [header, payload, signature] = token.split('.');
  if (!header || !payload || !signature) {
    return null;
  }
  return JSON.parse(atob(payload));
};
