import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import { getRedirectRequestPayload, setLoggedInUserEmail } from '../../common/Utils';
import { IPublicClientApplication } from '@azure/msal-browser';

export interface SignInProps {
  instance: IPublicClientApplication;
}

const theme = createTheme();
export const SignIn: React.FC<SignInProps> = ({ instance }) => {
  const [email, setEmail] = useState<string>('');

  const initializeSignIn = () => {
    if (!email) return;
    instance.loginRedirect(getRedirectRequestPayload(email));
    setLoggedInUserEmail(email);
  };

  const handleSubmit = (event?: any) => {
    event.preventDefault();
    initializeSignIn();
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => {
                const value = e?.target?.value;
                setEmail(value);
              }}
              autoFocus
            />
            <Button disabled={!email} type="button" onClick={handleSubmit} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
          </Box>
        </Box>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2, mb: 4 }}>
          {'Copyright © '}
          <Link color="inherit" href="https://coadjute.com/">
            coadjute.com
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
    </ThemeProvider>
  );
};
