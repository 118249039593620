export enum WindowType {
  COADJUTE_CLIENT_ID = 'COADJUTE_CLIENT_ID',
  COADJUTE_OPEN_API_SPEC = 'COADJUTE_OPEN_API_SPEC',
}

export enum LocalStorageKeys {
  AccessToken = 'AccessToken',
  LoggedInUserEmail = 'LoggedInUserEmail',
  IsBrowserIncognito = 'IsBrowserIncognito',
}
