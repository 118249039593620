import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { SignIn } from './pages/SignIn/SignIn';
import { debounceLogic, getIsBrowserIncognito, getLoggedInUserEmail, getPopupRequest, getSsoSilentRequestPayload, setIsBrowserIncognito } from './common/Utils';
import { RedocStandalone } from 'redoc';
import { AddBearerTokenAsHeader } from './common/AuthInterceptor';
import { Loader } from './components/Loader/Loader';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { BrowserAuthError } from '@azure/msal-browser';
import { Alert, Snackbar } from '@mui/material';
import './App.css';

function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [accessToken, setAccessToken] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>();
  const [showEnablePopupError, setShowEnablePopupError] = useState<boolean>(false);

  const configureAccessToken = (token: string) => {
    AddBearerTokenAsHeader(token);
    debounceLogic(() => {
      setLoading(false);
      setAccessToken(token);
    });
  };

  useEffect(() => {
    const getSsoSilent = async (email: string) => {
      // Do not perform SSO Silent as it would fail in Incognito Mode
      const isBrowserIncognito = getIsBrowserIncognito();
      if (isBrowserIncognito && typeof isBrowserIncognito) {
        instance
          .acquireTokenPopup(getPopupRequest(email))
          .then((response: any) => {
            if (response?.idToken) {
              configureAccessToken(response?.idToken);
            }
          })
          .catch((error: any) => {
            if (error instanceof BrowserAuthError && error?.errorCode === 'popup_window_error') {
              // Set Error Screen To Enable Browser Popups
              setShowEnablePopupError(true);
            }
          });
      } else {
        instance
          .ssoSilent(getSsoSilentRequestPayload(email))
          .then((response: any) => {
            if (response?.idToken) {
              configureAccessToken(response?.idToken);
            }
          })
          .catch((error: any) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            if (error instanceof InteractionRequiredAuthError) {
              setIsBrowserIncognito(true);

              debounceLogic(() => {
                setRefresh((prev) => !prev);
              });
            }
          });
      }
    };

    if (isAuthenticated) {
      setLoading(true);
      // First try to get the logged in user email and fetch the sso silently
      const loggedInUserEmail = getLoggedInUserEmail();
      if (loggedInUserEmail) {
        getSsoSilent(loggedInUserEmail);
      }
    }
  }, [accounts, instance, isAuthenticated, refresh]);

  return (
    <div className="App">
      {loading ? (
        <Loader displayLoader={loading} />
      ) : !accessToken ? (
        <SignIn instance={instance} />
      ) : (
        <RedocStandalone
          specUrl={window.COADJUTE_OPEN_API_SPEC}
          options={{
            hideDownloadButton: true,
            sortPropsAlphabetically: true,
            theme: { colors: { primary: { main: '#323031' } } },
          }}
        />
      )}
      <Snackbar open={showEnablePopupError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={'error'} sx={{ color: 'black' }}>
          Please enable the popups if you wish to login using Incognito Mode (Private Browsing) or else try logging in using regular browser.
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
