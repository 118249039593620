import React, { FC } from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';
import './loader.css';

export interface LoaderProps {
  displayLoader: boolean;
}

export const Loader: FC<LoaderProps & CircularProgressProps> = (props: any) => {
  const { displayLoader, ...circularProgressProps } = props;
  return displayLoader ? (
    <div
      style={{
        marginTop: '20vh',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <CircularProgress {...circularProgressProps} sx={{ marginTop: '30px' }} className="loader" />
    </div>
  ) : null;
};
